function onEntry(entry) {
    entry.forEach(change => {
        if (change.isIntersecting) {
            change.target.classList.add('element-show');
        }
    });
}
let options = { threshold: [0.5] };
let observer = new IntersectionObserver(onEntry, options);
let elementsAnimate = document.querySelectorAll('.animate');
for (let elm of elementsAnimate) {
    observer.observe(elm);
}









let smileOneTop =  [-10, -20, -10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220]
let smileOneLeft = [-30, -45, -40, -35, -30, -25, -20, -15, -5, -3, 0, 5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 110, 110]

let smileTwoTop =  [220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570, 580, 590];
let smileTwoLeft = [110, 120, 140, 150, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 155, 150, 150, 150, 145, 140, 135, 130, 125, 120, 115, 110, 100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0];

let smileThreeTop =  [590, 610, 630, 650, 670, 690, 710, 730, 745, 760, 780, 800, 820, 840, 860, 880, 900, 920, 940, 965]
let smileThreeLeft = [0, -10, -10, -20, -20, -30, -30, -40, -45, -35, -25, -15, 0, 15, 30, 45, 60, 75, 90, 100]

let smileFourTop =  [965,     985, 1005, 1025, 1045, 1065, 1085, 1105,    1115,    1135, 1155, 1175, 1195, 1215, 1235, 1255, 1275, 1290,     1300]
let smileFourLeft = [100,     105, 115, 120, 125, 130, 140, 145,          150,     140, 130, 120, 110, 100, 90, 80, 70, 60,     50]

let smileFiveTop =  [1300,   1330, 1360, 1390, 1410, 1440, 1470, 1490,    1495,    1525, 1545, 1565, 1585, 1600, 1625, 1650, 1670, 1680,     1695]
let smileFiveLeft = [50,     35, 20, 5, -5, -20, -30, -40,               -50,     -40, -30, -20, -5, 5, 15, 30, 40, 45,                    50]

let smileSixTop =  [1695,   1720, 1750, 1770, 1790, 1820, 1835, 1850,    1870,    1890, 1910, 1930, 1950, 1970, 1990, 2010, 2030, 2045,     2055]
let smileSixLeft = [50,     60, 75, 90, 105, 120, 130, 140,              150,     140, 130, 120, 110, 100, 95, 90, 85, 80,                    75]


let smileSevenTop =  [2055,   2080, 2110, 2140, 2170, 2200, 2220, 2240,    2255,    2270, 2290, 2310, 2330, 2350, 2370, 2390, 2400, 2405,     2410]
let smileSevenLeft = [75,     55, 35, 15, -5, -25, -35, -45,              -55,     -45, -35, -30, -25, -20, -15, -10, -5, 0,                    0]


let smileEightTop =  [2410,   2450, 2490, 2530, 2570, 2600, 2630, 2660,    2700,    2730, 2760, 2790, 2810, 2840, 2870, 2900, 2930, 2960,     3000]
let smileEightLeft = [0,     20, 40, 60, 80, 100, 120, 140,              160,     145, 130, 115, 100, 85, 80, 75, 70, 65,                    60]




function offset(el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}
const strokeElem = document.getElementById("animate-stroke");
const smileOne = document.getElementById("smile-one");
const smileTwo = document.getElementById("smile-two");
const smileThree = document.getElementById("smile-three");
const smileFour = document.getElementById("smile-four");
const smileFive = document.getElementById("smile-five");
const smileSix = document.getElementById("smile-six");
const smileSeven = document.getElementById("smile-seven");
const smileEight = document.getElementById("smile-eight");
const smileNine = document.getElementById("smile-nine");

const review = document.getElementById("review");
const reviewVideo = document.getElementById("review-video");
let reviewStart = offset(review);



let start = offset(strokeElem);
let firstStart = offset(smileOne);
let firstFinish = offset(smileTwo);
let twoStart = offset(smileTwo);
let twoFinish = offset(smileThree);
let threeStart = offset(smileThree);
let threeFinish = offset(smileFour);
let fourStart = offset(smileFour);
let fourFinish = offset(smileFive);
let fiveStart = offset(smileFive);
let fiveFinish = offset(smileSix);
let sixStart = offset(smileSix);
let sixFinish = offset(smileSeven);
let sevenStart = offset(smileSeven);
let sevenFinish = offset(smileEight);
let eightStart = offset(smileEight);
let eightFinish = offset(smileNine);

let elements = document.querySelectorAll('.way__steps-stroke');

let smileOneStep =  (firstFinish.top - firstStart.top) / 26
let smileTwoStep =  (twoFinish.top - twoStart.top) / 38
let smileThreeStep =  (threeFinish.top - threeStart.top) / 20
let smileFourStep =  (fourFinish.top - fourStart.top) / 19
let smileFiveStep =  (fiveFinish.top - fiveStart.top) / 19
let smileSixStep =  (sixFinish.top - sixStart.top) / 19
let smileSevenStep =  (sevenFinish.top - sevenStart.top) / 19
let smileEightStep =  (eightFinish.top - eightStart.top) / 19


addEventListener('scroll', () => {

    let scrolPosition = window.scrollY + 300;




    if (scrolPosition >= firstStart.top && scrolPosition <= firstFinish.top){
       position = Math.round((scrolPosition - firstStart.top) / smileOneStep);
       smileOne.style.top = smileOneTop[position] + 'px';
       smileOne.style.left = smileOneLeft[position] + 'px';
    }
    if (scrolPosition < firstStart.top){
        smileOne.style.top = smileOneTop[0] + 'px';
        smileOne.style.left = smileOneLeft[0] + 'px';
    }
    if (scrolPosition > firstFinish.top){
        smileOne.style.top = smileOneTop[25] + 'px';
        smileOne.style.left = smileOneLeft[25] + 'px';
    }

    if (scrolPosition >= twoStart.top && scrolPosition <= twoFinish.top){
        position = Math.round((scrolPosition - twoStart.top) / smileTwoStep);
        smileTwo.style.top = smileTwoTop[position] + 'px';
        smileTwo.style.left = smileTwoLeft[position] + 'px';
    }

    if (scrolPosition < twoStart.top){
        smileTwo.style.top = smileTwoTop[0] + 'px';
        smileTwo.style.left = smileTwoLeft[0] + 'px';
    }
    if (scrolPosition > twoFinish.top){
        smileTwo.style.top = smileTwoTop[37] + 'px';
        smileTwo.style.left = smileTwoLeft[37] + 'px';
    }







    if (scrolPosition >= threeStart.top && scrolPosition <= threeFinish.top){
        position = Math.round((scrolPosition - threeStart.top) / smileThreeStep);
        smileThree.style.top = smileThreeTop[position] + 'px';
        smileThree.style.left = smileThreeLeft[position] + 'px';
    }

    if (scrolPosition < threeStart.top){
        smileThree.style.top = smileThreeTop[0] + 'px';
        smileThree.style.left = smileThreeLeft[0] + 'px';
    }
    if (scrolPosition > threeFinish.top){
        smileThree.style.top = smileThreeTop[19] + 'px';
        smileThree.style.left = smileThreeLeft[19] + 'px';
    }




    if (scrolPosition >= fourStart.top && scrolPosition <= fourFinish.top){
        position = Math.round((scrolPosition - fourStart.top) / smileFourStep);
        smileFour.style.top = smileFourTop[position] + 'px';
        smileFour.style.left = smileFourLeft[position] + 'px';
    }

    if (scrolPosition < fourStart.top){
        smileFour.style.top = smileFourTop[0] + 'px';
        smileFour.style.left = smileFourLeft[0] + 'px';
    }
    if (scrolPosition > fourFinish.top){
        smileFour.style.top = smileFourTop[18] + 'px';
        smileFour.style.left = smileFourLeft[18] + 'px';
    }



    if (scrolPosition >= fiveStart.top && scrolPosition <= fiveFinish.top){
        position = Math.round((scrolPosition - fiveStart.top) / smileFiveStep);
        smileFive.style.top = smileFiveTop[position] + 'px';
        smileFive.style.left = smileFiveLeft[position] + 'px';
    }

    if (scrolPosition < fiveStart.top){
        smileFive.style.top = smileFiveTop[0] + 'px';
        smileFive.style.left = smileFiveLeft[0] + 'px';
    }
    if (scrolPosition > fiveFinish.top){
        smileFive.style.top = smileFiveTop[18] + 'px';
        smileFive.style.left = smileFiveLeft[18] + 'px';
    }




    if (scrolPosition >= sixStart.top && scrolPosition <= sixFinish.top){
        position = Math.round((scrolPosition - sixStart.top) / smileSixStep);
        smileSix.style.top = smileSixTop[position] + 'px';
        smileSix.style.left = smileSixLeft[position] + 'px';
    }

    if (scrolPosition < sixStart.top){
        smileSix.style.top = smileSixTop[0] + 'px';
        smileSix.style.left = smileSixLeft[0] + 'px';
    }
    if (scrolPosition > sixFinish.top){
        smileSix.style.top = smileSixTop[18] + 'px';
        smileSix.style.left = smileSixLeft[18] + 'px';
    }



    if (scrolPosition >= sevenStart.top && scrolPosition <= sevenFinish.top){
        position = Math.round((scrolPosition - sevenStart.top) / smileSevenStep);
        smileSeven.style.top = smileSevenTop[position] + 'px';
        smileSeven.style.left = smileSevenLeft[position] + 'px';
    }

    if (scrolPosition < sevenStart.top){
        smileSeven.style.top = smileSevenTop[0] + 'px';
        smileSeven.style.left = smileSevenLeft[0] + 'px';
    }
    if (scrolPosition > sevenFinish.top){
        smileSeven.style.top = smileSevenTop[18] + 'px';
        smileSeven.style.left = smileSevenLeft[18] + 'px';
    }




    if (scrolPosition >= eightStart.top && scrolPosition <= eightFinish.top){
        position = Math.round((scrolPosition - eightStart.top) / smileEightStep);
        smileEight.style.top = smileEightTop[position] + 'px';
        smileEight.style.left = smileEightLeft[position] + 'px';
    }

    if (scrolPosition < eightStart.top){
        smileEight.style.top = smileEightTop[0] + 'px';
        smileEight.style.left = smileEightLeft[0] + 'px';
    }
    if (scrolPosition > eightFinish.top){
        smileEight.style.top = smileEightTop[18] + 'px';
        smileEight.style.left = smileEightLeft[18] + 'px';
        smileNine.style.display = 'none';
    }else{
        smileNine.style.display = 'block';
    }


    document.body.style.setProperty('--scroll', window.scrollY);
})



let elements_faq = document.querySelectorAll('.faq__item-title');
elements_faq.forEach((item) => {
    item.addEventListener('click',function (){
      item.parentNode.classList.toggle('faq__item--active');
    });
});


let popupBg = document.querySelector('.popup__bg'); // Фон попап окна
let popup = document.querySelector('.popup'); // Само окно
let openPopupButtons = document.querySelectorAll('.open-popup'); // Кнопки для показа окна
let closePopupButton = document.querySelector('.close-popup'); // Кнопка для скрытия окна


openPopupButtons.forEach((button) => { // Перебираем все кнопки
    button.addEventListener('click', (e) => { // Для каждой вешаем обработчик событий на клик
        e.preventDefault(); // Предотвращаем дефолтное поведение браузера
        popupBg.classList.add('active'); // Добавляем класс 'active' для фона
        popup.classList.add('active'); // И для самого окна
    })
});

closePopupButton.addEventListener('click',() => { // Вешаем обработчик на крестик
    popupBg.classList.remove('active'); // Убираем активный класс с фона
    popup.classList.remove('active'); // И с окна
});

document.addEventListener('click', (e) => { // Вешаем обработчик на весь документ
    if(e.target === popupBg) { // Если цель клика - фот, то:
        popupBg.classList.remove('active'); // Убираем активный класс с фона
        popup.classList.remove('active'); // И с окна
    }
});

function sendmail(){

}
$('#contact-form-submit').on('click', function(e) {
    e.preventDefault();
    $('#contact-form-error').css('display', 'none');
    var $data;
    $data = $('#contact-form').serializeArray();
    $.ajax({
        url: '/send-mail.php',
        type: 'post',
        data: $data,
        success: function(result) {
            if(result.status === 'success'){
                document.getElementById('contact-form').reset();
                popupBg.classList.remove('active'); // Убираем активный класс с фона
                popup.classList.remove('active'); // И с окна
            }else{
                $('#contact-form-error').css('display', 'block');
            }

        }
    });
});